import React from 'react';
import { HiOutlinePhone } from 'react-icons/hi';
import Image from 'next/image';
import Logo from '@assets/images/kohler_logo.svg';

const FreeQuoteHeader = () => {
  return (
    <div className='relative flex items-center logoResponsive pt-3 pb-2 pl-5'>
      <Image
        height='32'
        width='96'
        alt='Kohler'
        className='h-auto sm:h-10 mr-2 outline-none invert-0'
        src={Logo}
      />
      <div className='hidden lg:block border-l-[3px] pl-2 border-l-primary border-solid h-[22px]'></div>
      <div className='hidden lg:block mt-0 align-middle lg:mr-3 text-black '>
        <span className='font-helNowLight text-[22px] font-bold border-0 '>
          Generators
        </span>
      </div>
      <div className='absolute right-[20px] lg:right-[10px]'>
        <a
          role='link'
          href='tel:844-731-7989'
          hreflang='en'
          aria-label='844-731-7989'
          rel='noreferrer'
          target='_blank'
          className='mt-0 align-middle lg:mr-3 text-black'
        >
          <div className='hidden lg:block md:block relative flex flex-col mr-3 my-2 w-full cursor-pointer'>
            <div className='pl-10 pr-4 pt-6 font-helNowLight border-0 sm:text-[22px]'>
              <div className='absolute inset-y-0 left-0 pl-3 pt-6 flex items-center'>
                <HiOutlinePhone color='#4171DF' />
              </div>
              <span className='font-ProMedium text-[22px] border-0 '>
                Call Now 844-731-7989
              </span>
            </div>
          </div>

          <div className='font-ProMedium font-bold text-[10px] border-0 pl-5 pt-1 md:hidden lg:hidden'>
            Call For a Free Quote
          </div>
          <div className='absolute inset-y-0 left-0 flex items-center pt-4 md:hidden lg:hidden'>
            <HiOutlinePhone color='#4171DF' /> {'  '}
          </div>
          <div className='font-ProMedium font-bold pl-5 pb-1 text-[16px] md:hidden lg:hidden'>
            844-731-7989
          </div>
        </a>
      </div>
    </div>
  );
};

export default FreeQuoteHeader;
