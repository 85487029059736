import React, { Fragment, memo, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { HiSearch } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { getIP, getLocation, getLocationIP } from '@redux/actions/customer';
import { XMarkIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import Logo from '@assets/images/kohler_logo.svg';
import { getLocales } from '@services/contentful/api';
//import Accordion from '@components/accordion';
import Dropdown from '@components/dropdown';
import NavItems from '@components/header/NavItems';
import ProductSearch from '@components/productSearch';
import SearchMobile from '@components/productSearch/SearchMobile';
import { isEnterOrSpaceKey } from '@utils/common';
import { openInNewTab } from '@utils/index';
import { onEnterSpacePress } from '@utils/keyboard';
import NewSearch from './NewSearch';
import Location from './location';
import FreeQuoteHeader from './freeQuoteHeader';

/**
 * @class
 * @param {Object} primaryNavHeaderData Primary Navigation data for headers
 * @param {Object} secondaryNav Secondary Navigation data for headers
 * @param {Object} locales Locales data for headers
 *
 * @returns Header Component as per the navigation data
 */

const UtilityNavRibbon = ({ locales, location }) => (
  <div className='lg:hidden bg-primary p-2 px-8 text-sm '>
    <li className='text-white flex font-helNowLight align-middle py-2 mt-2'>
      <Location
        location={location}
        divider={false}
        classNames={{
          icon: 'h-8',
          text: '!text-xl',
          container:
            'flex border-t-0 border-r-0 border-l-0 border-solid w-full pb-2'
        }}
      />
    </li>
    {/* Enable the below section to show the Language accordion in Mobile view */}
    {/* <li className='!text-white py-2 flex font-helNowLight align-middle w-full'>
      <Accordion
        classNames={{
          container:
            'border-b-2 border-t-0 border-r-0 border-l-0 border-solid !bg-transparent pb-3 text-xl !mx-0 w-full',
          content: '!p-0',
          header: 'px-3 font-normal !text-white'
        }}
        upDownIcons={true}
        header={locales?.[0]?.label}
      >
        <ul>
          {locales?.map(locale => (
            <li key={locale.label} className='p-3 font-light'>
              {locale.label}
            </li>
          ))}
        </ul>
      </Accordion>
    </li> */}
  </div>
);

const Header = ({
  primaryNavHeaderData,
  secondaryNavHeaderData,
  locales,
  isHeroWithHeading,
  overlay,
  handleSearchOpen
}) => {
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showSubmenuMenuMobile, setShowSubmenuMenuMobile] = useState(false);
  const [subMenuNavItems, setSubMenuNavItems] = useState([]);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const router = useRouter();
  const [isMenuPanelOpen, setMenuPanelOpen] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [showSearch, setShowSearch] = useState(false);
  const searchOpenAndOverlay = searchOpen && overlay;
  useEffect(() => {
    try {
      const getData = () => {
        try {
          if (!user?.ip) {
            getIP(dispatch)();
            getLocationIP(dispatch)(user.ip);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const getPosition = position => {
        try {
          getLocation(dispatch)(position.coords);
        } catch (err) {
          console.log(err);
        }
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getPosition, getData, {
          enableHighAccuracy: true
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, user.ip]);

  useEffect(() => {
    if (!overlay && searchOpen) {
      setSearchOpen(!searchOpen);
    }
  }, [overlay, searchOpen]);

  const handleMouseLeave = () => {
    setMenuPanelOpen(null);
  };
  const handleMobileSubmenuClick = event => {
    const subMenuItem = secondaryNavHeaderData?.find(
      item => item.fields.label === event.target.text
    )?.fields;
    if (subMenuItem) {
      if (subMenuItem.navItems?.length > 0) {
        setSubMenuNavItems(subMenuItem.navItems);
        setShowSubmenuMenuMobile(true);
      } else if (subMenuItem.urlOpenerType === 'New Tab') {
        openInNewTab(subMenuItem.url);
      } else if (subMenuItem.url) {
        setShowMenuModal(false);
        router.push(subMenuItem.url);
      }
    }
  };

  const menuIconClasses = cx(
    'text-sm',
    'lg:flex',
    'font-bold',
    'lg:flex-grow',
    'lg:ml-3',
    'pt-1'
  );

  const mobileMenuIcons = cx(
    'relative',
    'flex',
    'w-full',
    'lg:w-auto',
    'lg:static',
    'lg:block',
    'lg:justify-start'
  );

  const navClasses = cx(
    'flex-none',
    'lg:flex',
    'items-center',
    'justify-between',
    'lg:px-10',
    'lg:pl-12',
    'px-4',
    'py-3',
    'p-0',
    {
      'text-black bg-transparent lg:text-white lg:mt-0 lg:hover:bg-white lg:hover:text-black':
        isHeroWithHeading
    },
    {
      'bg-white top-0': searchOpenAndOverlay
    },
    {
      'lg:mt-0 bg-white': !isHeroWithHeading
    },
    'absolute',
    'z-[1]',
    'group',
    'w-full'
  );

  return (
    <Fragment>
      <div className={cx('relative main-header z-50')} data-testid='header'>
        <div className='max-w-full'>
          <div className='relative lg:w-full'>
            <div className='mb-0 bg-primary'>
              <div className='relative px-5 pt-2 lg:pr-6 lg:py-2 lg:pl-12'>
                <nav
                  className='relative flex items-center font-helNowLight justify-center lg:justify-between align-middle sm:h-15'
                  aria-label='Global'
                >
                  <div className='flex items-center'>
                    <ul className='flex list-none m-0 p-0'>
                      <NavItems
                        active={router.asPath}
                        navItems={primaryNavHeaderData}
                        topNav={true}
                        onClick={handleMobileSubmenuClick}
                        isMenuPanelOpen={isMenuPanelOpen}
                        setMenuPanelOpen={setMenuPanelOpen}
                      />
                    </ul>
                  </div>
                  <div className='items-center lg:flex align-middle text-sm hidden'>
                    <Location location={user.location} divider={false} />
                    {/* {locales && (
                      <Dropdown
                        label={locales[0]?.label}
                        icon={
                          <Image
                            alt={'United States - English'}
                            className='h-auto sm:h-5 sm:w-6 outline-none mr-2'
                            height='5'
                            width='96'
                            src={locales[0]?.icon}
                            priority={true}
                          />
                        }
                        className='font-helNowLight pt-1'
                        options={locales}
                        hideDownIcon
                      />
                    )} */}
                  </div>
                </nav>
              </div>
            </div>
            {router.asPath.includes('get-a-free-quote-now') ? (
              <FreeQuoteHeader />
            ) : (
              !showSearch && (
                <nav
                  className={navClasses}
                  id='main-navigation'
                  aria-label='header'
                  onMouseLeave={handleMouseLeave}
                >
                  <div className='flex-none lg:flex w-full lg:w-auto border-0'>
                    <div className={mobileMenuIcons}>
                      <Link href={'/'} legacyBehavior>
                        <a
                          className={cx(
                            'flex-nowrap mr-4 flex lg:relative order-2 no-underline float-right lg:right-[0px] lg:mr-2 lg:flex-grow',
                            { 'hidden lg:flex': searchOpenAndOverlay }
                          )}
                        >
                          {!showSearch && (
                            <div className='flex items-center logoResponsive md:pt-2 pt-3 pb-2'>
                              <Image
                                height='32'
                                width='96'
                                alt='Kohler'
                                className={cx(
                                  'h-auto sm:h-10 mr-2 outline-none',
                                  {
                                    'lg:group-hover:invert-0 invert':
                                      isHeroWithHeading
                                  },
                                  {
                                    'invert-0': searchOpenAndOverlay
                                  }
                                )}
                                src={Logo}
                              />
                              <div className='border-l-[3px] pl-2 border-l-primary border-solid h-[22px]'></div>
                              <div
                                className={cx(
                                  'mt-0 align-middle lg:mr-3 ',
                                  {
                                    'text-white lg:group-hover:text-black':
                                      isHeroWithHeading
                                  },
                                  {
                                    'text-black':
                                      !isHeroWithHeading || searchOpenAndOverlay
                                  }
                                )}
                              >
                                <span className='font-helNowLight text-[13px] font-bold border-0 sm:text-[22px] '>
                                  Generators
                                </span>
                              </div>
                            </div>
                          )}
                        </a>
                      </Link>
                      {searchOpenAndOverlay ? (
                        <ProductSearch
                          searchOpen={searchOpen}
                          inputType='search'
                          containerClasses={{
                            parent: 'w-full mb-3 z-[1] lg:hidden',
                            input: 'text-black'
                          }}
                        />
                      ) : (
                        <>
                          <button
                            className='flex px-1 py-1 order-1 text-xl leading-none bg-transparent border-r-0 border-l-0 border-t-0 border-b-0 rounded-none mr-1 cursor-pointer sm:mr-4 sm:px-3 lg:hidden lg:outline-none'
                            type='button'
                            aria-label='menu'
                            aria-haspopup='true'
                            aria-expanded={showMenuModal}
                            onClick={() => setShowMenuModal(true)}
                          >
                            <Bars3Icon
                              className={cx(
                                'align-middle h-7 text-white-500',
                                {
                                  'text-white': isHeroWithHeading
                                },
                                {
                                  'text-black': !isHeroWithHeading || searchOpen
                                }
                              )}
                            />
                          </button>
                        </>
                      )}
                      <span
                        onClick={() => setShowSearch(true)}
                        onKeyDown={e =>
                          isEnterOrSpaceKey(e) && setShowSearch(true)
                        }
                        className='lg:hidden absolute right-3 top-3 sm:right-6'
                      >
                        <HiSearch
                          className={cx(
                            'align-middle h-7 text-white-500',
                            {
                              'text-white': isHeroWithHeading
                            },
                            {
                              'text-black': !isHeroWithHeading || searchOpen
                            }
                          )}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      'w-full flex bg-inherit lg:items-center lg:w-auto lg:flex flex-grow lg:pl-2' +
                      (navbarOpen ? ' flex' : ' hidden')
                    }
                  >
                    {searchOpenAndOverlay ? (
                      <ProductSearch
                        inputType='search'
                        containerClasses={{
                          parent: 'w-full mb-3 z-[1]',
                          input: 'text-black'
                        }}
                      />
                    ) : (
                      <>
                        <div className={menuIconClasses}>
                          <ul className='list-none w-full lg:flex m-0 p-0'>
                            <NavItems
                              isHeroWithHeading={isHeroWithHeading}
                              active={router.asPath}
                              navItems={secondaryNavHeaderData}
                              onClick={() => setNavbarOpen(false)}
                              isMenuPanelOpen={isMenuPanelOpen}
                              setMenuPanelOpen={setMenuPanelOpen}
                              topNav={false}
                            />
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                  <>
                    {showSearchModal ? (
                      <FocusLock>
                        <div className='h-full flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none lg:hidden'>
                          <div className='relative w-full h-full mx-auto '>
                            <div className='border-0 shadow-lg h-full relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                              <div className='flex items-start justify-between p-5 border-slate-200 rounded-t'>
                                <button
                                  className='cursor-pointer p-1 bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold'
                                  aria-label='Close Menu'
                                  tabIndex={0}
                                  onClick={() => {
                                    setShowSearchModal(false);
                                  }}
                                >
                                  <span className='bg-transparent text-black h-9 w-9 text-2xl block'>
                                    <XMarkIcon className='align-middle h-9 text-white-500 stroke-slate-50' />
                                  </span>
                                </button>
                              </div>
                              <div className='relative p-6 flex-auto'>
                                <SearchMobile
                                  setShowSearchModal={setShowSearchModal}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='opacity-25 fixed inset-0 z-40 bg-black lg:hidden'></div>
                      </FocusLock>
                    ) : null}
                    {showMenuModal ? (
                      <>
                        <div className='h-full flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none lg:hidden'>
                          <div className='relative w-full h-full mx-auto '>
                            <div className='border-0 shadow-lg h-full relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                              <div className='flex items-start justify-between p-5   border-slate-200 rounded-t'>
                                <button
                                  className='p-1  bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                                  onClick={() => {
                                    setShowMenuModal(false);
                                    setShowSubmenuMenuMobile(false);
                                  }}
                                >
                                  <span className='bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none'>
                                    <XMarkIcon className='align-middle h-9' />
                                  </span>
                                </button>
                              </div>

                              <div className='relative px-8'>
                                <ul className='relative list-none lg:flex m-0 p-0'>
                                  {showSubmenuMenuMobile ? (
                                    <>
                                      <div
                                        onClick={() =>
                                          setShowSubmenuMenuMobile(false)
                                        }
                                        onKeyDown={onEnterSpacePress(() =>
                                          setShowSubmenuMenuMobile(false)
                                        )}
                                        className=' w-full leading-5 flex'
                                      >
                                        <ChevronLeftIcon className='float-left -top-2 relative align-middle h-8 text-white-500 stroke-slate-50' />{' '}
                                        Menu
                                      </div>
                                      <NavItems
                                        isHeroWithHeading={isHeroWithHeading}
                                        active={router.asPath}
                                        navItems={subMenuNavItems}
                                        onClick={() => {
                                          setShowMenuModal(false);
                                          setShowSubmenuMenuMobile(false);
                                        }}
                                        isMenuPanelOpen={isMenuPanelOpen}
                                        setMenuPanelOpen={setMenuPanelOpen}
                                        topNav={false}
                                      />
                                    </>
                                  ) : (
                                    <NavItems
                                      isHeroWithHeading={isHeroWithHeading}
                                      active={router.asPath}
                                      navItems={secondaryNavHeaderData}
                                      onClick={handleMobileSubmenuClick}
                                      isMenuPanelOpen={isMenuPanelOpen}
                                      setMenuPanelOpen={setMenuPanelOpen}
                                    />
                                  )}
                                </ul>
                              </div>
                              <UtilityNavRibbon
                                locales={locales}
                                location={user.location}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='opacity-25 fixed inset-0 z-40 bg-black lg:hidden'></div>
                      </>
                    ) : null}
                  </>
                  <button
                    className='hidden lg:block cursor-pointer'
                    onClick={() => setShowSearch(true)}
                    onKeyDown={e => isEnterOrSpaceKey(e) && setShowSearch(true)}
                  >
                    <ProductSearch setShowSearchModal={setShowSearchModal} />
                  </button>
                </nav>
              )
            )}
            {showSearch && (
              <NewSearch
                secondaryNavHeaderData={secondaryNavHeaderData}
                showSearch={showSearch}
                setShowSearch={setShowSearch}
                setShowSearchModal={setShowSearchModal}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default memo(Header);
